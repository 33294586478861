import React, {useState, useEffect} from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { rgba } from "polished";
import Section from "../components/Section";
import {Helmet} from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner} from '@fortawesome/free-solid-svg-icons'
import { Title, Text } from "../components/Core";
import { useQueryParam, StringParam } from "use-query-params";
import gopages from '../components/data/go/go.json'

const ContentIcon = styled.div`
  width: 118px;
  height: 118px;
  background-color: ${({ theme }) => rgba(theme.colors.primary, 0.1)};
  border-radius: 500px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
`;



const Redirect = ({location}) => {

  const [go, setGo] = useQueryParam("go", StringParam);
  const [url, setUrl] = useQueryParam("url", StringParam);


console.log('url', url)

let goin = gopages.find(Bank => Bank.BanqueName === go)
let going = ''

if(gopages.find(Bank => Bank.BanqueName === go)){
  going = goin['Url']
}


useEffect(() => {
  setTimeout(()=> {
    document.location.href = going
}, 300)
})
  
const [spinner, setSpinner] = useState('spinnerFalse')
  return (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Veuillez patientez, vous allez bientôt être redirigé</title>
            <meta name="description" content="Page de redirection vers l'offre de votre choix" />
        </Helmet>

        <Section>
            <div className="pt-5"></div>
            <Container>
            <div className="text-center">
                <ContentIcon>
                <FontAwesomeIcon className={'fa-spin'} icon={faSpinner} id={spinner} style={{color: '#e4234f', fontSize: 56}}/>
                </ContentIcon>
                <div>
                <Title variant="hero" style={{textAlign: 'center'}}>Veuillez patienter</Title>
                <Text>
                    Vous allez être redirigé vers {go}
                </Text>
                </div>
            </div>
            </Container>
        </Section>
    </>
  );
};

export default Redirect;

